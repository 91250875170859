import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home/Home';
import Sidebar from './Pages/Shared/Sidebar/Sidebar';
import About from './Pages/About/About/About';
import Services from './Pages/Services/Services/Services';
import Projects from './Pages/Projects/Projects/Projects';
import WebDesign from './Pages/Projects/Web Design/WebDesign';
import Contact from './Pages/Contact/Contact/Contact';
import Blogs from './Pages/Blogs/Blogs';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Sidebar />
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/home' element={<Home />}></Route>
          <Route exact path='/about' element={<About />}></Route>
          <Route exact path='/services' element={<Services />}></Route>
          <Route exact path='/projects' element={<Projects />}></Route>
          <Route exact path='/projects/:id' element={<WebDesign />}></Route>
          <Route exact path='/contact' element={<Contact />}></Route>
          <Route exact path='/blogs' element={<Blogs />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
