import React from 'react';

const Services = () => {
    return (
      <div className="section">
        <h2>this is services</h2>
      </div>
    );
};

export default Services;