import React from 'react';

const Blogs = () => {
    return (
      <div className="section section-two">
        <div className="section-title">
          <h2>Blogs</h2>
        </div>
        <div className="text-center" style={{marginTop: '50px'}}>
            <h3>Blogs Comming Soon!</h3>
        </div>
      </div>
    );
};

export default Blogs;